<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("monitoringQuestionCategory.questionCategoryKh")
                }}</label>
                <input
                    ref="question_cat_name_kh"
                    v-model="model.question_cat_name_kh"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('question_cat_name_kh ')
                    }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('question_cat_name_kh ')"
                >
                    {{ errors.first("question_cat_name_kh ") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("monitoringQuestionCategory.questionCategoryEn")
                }}</label>
                <input
                    v-model="model.question_cat_name_en"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('question_cat_name_en ')
                    }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('question_cat_name_en ')"
                >
                    {{ errors.first("question_cat_name_en ") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("monitoringQuestionCategory.jobTitleSurveyor")
                }}</label>
                <Select
                    v-model="model.job_title_surveyor"
                    filterable
                    :loading="surveyorLoading"
                    :clearable="true"
                    :multiple="true"
                    :max-tag-count="2"
                    width="100%"
                >
                    <Option
                        v-for="(option, index) in surveyors"
                        :value="option.job_title_id"
                        :key="index"
                        >{{ option.job_title_en }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('job_title_id')">
                    {{ errors.first("job_title_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("monitoringQuestionCategory.jobTitleTobeSurveyed")
                }}</label>
                <Select
                    v-model="model.job_title_to_be_surveyed"
                    filterable
                    :loading="surveyorLoading"
                    :clearable="true"
                    :multiple="true"
                    :max-tag-count="2"
                    width="100%"
                >
                    <Option
                        v-for="(option, index) in surveyors"
                        :value="option.job_title_id"
                        :key="index"
                        >{{ option.job_title_en }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('job_title_id')">
                    {{ errors.first("job_title_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("monitoringQuestionCategory.descriptionKh")
                }}</label>
                <textarea
                    v-model="model.description_kh"
                    rows="3"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('description_kh') }"
                ></textarea>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('description_kh')"
                >
                    {{ errors.first("description_kh") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("monitoringQuestionCategory.descriptionEn")
                }}</label>
                <textarea
                    v-model="model.description_en"
                    rows="3"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('description_en') }"
                ></textarea>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('description_en')"
                >
                    {{ errors.first("description_en") }}
                </div>
            </div>
        </div>
        <div class="mt-3">
            <ts-checkbox v-model="model.is_customer_answer">{{
                $t("monitoringQuestionCategory.isCustomerAnswer")
            }}</ts-checkbox>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { mapActions } from "vuex/dist/vuex.common.js";
export default {
    name: "monitoringQuestionCategoryForm",
    data() {
        return {
            errors: new Errors(),
            surveyorLoading: false,
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                question_cat_name_kh: null,
                question_cat_name_en: null,
                question_cat_id: null,
                job_title_surveyor: [],
                job_title_to_be_surveyed: [],
                is_customer_answer: false,
                inactive: false,
                description_en: null,
                description_kh: null
            }
        };
    },
    computed: {
        ...mapState("creditOperation/monitoringQuestionCategory", [
            "edit_data",
            "surveyors"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        ...mapActions("creditOperation/monitoringQuestionCategory", [
            "getSurveyor"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getSurveyor();
            this.setEditData();
            this.loading = false;
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch(
                    "creditOperation/monitoringQuestionCategory/store",
                    this.model
                )
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "creditOperation/monitoringQuestionCategory/store",
                    this.model
                )
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/monitoringQuestionCategory/update", {
                    id: this.edit_data.question_cat_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.question_cat_name_kh.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.question_cat_name_kh = this.edit_data.question_cat_name_kh;
                this.model.question_cat_name_en = this.edit_data.question_cat_name_en;
                this.model.question_cat_id = this.edit_data.question_cat_id;
                this.model.description_en = this.edit_data.description_en;
                this.model.description_kh = this.edit_data.description_kh;
                this.model.is_customer_answer = this.edit_data.is_customer_answer;
                this.model.job_title_surveyor = this.mapJobTitles(
                    this.edit_data.job_title_surveyor
                );
                this.model.job_title_to_be_surveyed = this.mapJobTitles(
                    this.edit_data.job_title_to_be_surveyed
                );
            }
        },
        mapJobTitles(jobtitle) {
            return jobtitle && jobtitle.length
                ? jobtitle.map(function($item) {
                      return $item.job_title_id;
                  })
                : [];
        },
        clearInput() {
            this.model.question_cat_name_kh = null;
            this.model.question_cat_name_en = null;
            this.model.description_en = null;
            this.model.description_kh = null;
            this.model.job_title_surveyor = null;
            this.model.job_title_to_be_surveyed = null;
            this.model.is_customer_answer = false;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONITORING QUESTION CATEGORY",
                desc: not.text
            });
        }
    }
};
</script>
