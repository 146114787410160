<template>
    <div>
        <ts-page-title
            :title="$t('monitoringQuestionCategory.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monitoringQuestionCategory.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t("addNew")
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('monitoringQuestionCategory.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="jobTitleSurveyor">
                    <div
                        v-for="(title, index) in row.job_title_surveyor_names"
                        :key="index"
                    >
                        <span>{{ title.job_title_en }} </span>
                    </div>
                </template>

                <template slot-scope="{ row }" slot="jobTitleTobeSurveyed">
                    <div
                        v-for="(title,
                        index) in row.job_title_to_be_surveyed_names"
                        :key="index"
                    >
                        <span>{{ title.job_title_en }}</span>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="inactive">
                    <i-switch
                        v-tooltip="$t('monitoringQuestionCategory.isInactive')"
                        size="small"
                        v-model="row.inactive"
                        :loading="row._switching"
                        @on-change="onChangeIsActive(row)"
                        style="margin-right: 4px"
                    >
                    </i-switch>
                </template>
                <template slot-scope="{ row }" slot="isCustomerAnswer">
                    <Icon
                        :type="
                            row.is_customer_answer === true
                                ? 'ios-checkmark-circle-outline'
                                : 'ios-close-circle-outline'
                        "
                        :class="
                            row.is_customer_answer === true
                                ? 'text-success'
                                : 'text-danger'
                        "
                        size="20"
                    />
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                width="500"
                :title="$t('monitoringQuestionCategory.setupCategory')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from "./form";
import { mapState } from "vuex/dist/vuex.common.js";
import { debounce } from "lodash";
export default {
    name: "monitoringQuestionCategoryIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("creditOperation/monitoringQuestionCategory", [
            "resources",
            "pagination"
        ]),
        search: {
            get() {
                return this.$store.state.creditOperation
                    .monitoringQuestionCategory.search;
            },
            set(newValue) {
                this.$store.commit(
                    "creditOperation/monitoringQuestionCategory/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "creditOperation/monitoringQuestionCategory/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    title: this.$t(
                        "monitoringQuestionCategory.questionCategoryKh"
                    ),
                    key: "question_cat_name_kh",
                    minWidth: 250
                },
                {
                    title: this.$t(
                        "monitoringQuestionCategory.questionCategoryEn"
                    ),
                    key: "question_cat_name_en",
                    minWidth: 250
                },
                {
                    title: this.$t(
                        "monitoringQuestionCategory.jobTitleSurveyor"
                    ),
                    slot: "jobTitleSurveyor",
                    minWidth: 250
                },
                {
                    title: this.$t(
                        "monitoringQuestionCategory.jobTitleTobeSurveyed"
                    ),
                    slot: "jobTitleTobeSurveyed",
                    minWidth: 250
                },
                {
                    title: this.$t("monitoringQuestionCategory.descriptionEn"),
                    key: "description_en",
                    minWidth: 250
                },
                {
                    title: this.$t("monitoringQuestionCategory.descriptionKh"),
                    key: "description_kh",
                    minWidth: 250
                },
                {
                    title: this.$t(
                        "monitoringQuestionCategory.isCustomerAnswer"
                    ),
                    slot: "isCustomerAnswer",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: this.$t("monitoringQuestionCategory.isInactive"),
                    slot: "inactive",
                    align: "center",
                    minWidth: 150,
                    filters: [
                        {
                            label: "Active",
                            value: false
                        },
                        {
                            label: "Inactive",
                            value: true
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                inactive: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 100
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/monitoringQuestionCategory/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit(
                "creditOperation/monitoringQuestionCategory/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
            this.$refs.form_action.fetchResource();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.clearInput();
            this.$store.commit(
                "creditOperation/monitoringQuestionCategory/SET_EDIT_DATA",
                {}
            );
        },
        addNew() {
            this.$store.commit(
                "creditOperation/monitoringQuestionCategory/SET_EDIT_DATA",
                {}
            );
            this.showForm = true;
            this.$refs.form_action.fetchResource();
            this.$refs.form_action.clearInput();
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "creditOperation/monitoringQuestionCategory/destroy",
                    record.question_cat_id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        async onChangeIsActive(record) {
            record._switching = true;
            this.$store
                .dispatch(
                    "creditOperation/monitoringQuestionCategory/changeIsActive",
                    {
                        id: record.question_cat_id,
                        data: { inactive: record.inactive }
                    }
                )
                .then(response => {
                    this.fetchData();
                    record._switching = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._switching = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONITORING QUESTION CATEGORY",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit(
            "creditOperation/monitoringQuestionCategory/RESET_STATE"
        );
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
