var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('monitoringQuestionCategory.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('monitoringQuestionCategory.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t("addNew")))]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('monitoringQuestionCategory.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"jobTitleSurveyor",fn:function(ref){
        var row = ref.row;
return _vm._l((row.job_title_surveyor_names),function(title,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(title.job_title_en)+" ")])])})}},{key:"jobTitleTobeSurveyed",fn:function(ref){
        var row = ref.row;
return _vm._l((row.job_title_to_be_surveyed_names),function(title,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(title.job_title_en))])])})}},{key:"inactive",fn:function(ref){
        var row = ref.row;
return [_c('i-switch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('monitoringQuestionCategory.isInactive')),expression:"$t('monitoringQuestionCategory.isInactive')"}],staticStyle:{"margin-right":"4px"},attrs:{"size":"small","loading":row._switching},on:{"on-change":function($event){return _vm.onChangeIsActive(row)}},model:{value:(row.inactive),callback:function ($$v) {_vm.$set(row, "inactive", $$v)},expression:"row.inactive"}})]}},{key:"isCustomerAnswer",fn:function(ref){
        var row = ref.row;
return [_c('Icon',{class:row.is_customer_answer === true
                            ? 'text-success'
                            : 'text-danger',attrs:{"type":row.is_customer_answer === true
                            ? 'ios-checkmark-circle-outline'
                            : 'ios-close-circle-outline',"size":"20"}})]}},{key:"action",fn:function(ref){
                            var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"width":"500","title":_vm.$t('monitoringQuestionCategory.setupCategory')},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{ref:"form_action",on:{"cancel":_vm.clearEdit,"fetchData":_vm.fetchData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }